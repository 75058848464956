<template>
  <main>
    <div class="relative">
      <div class="flex items-center mb-8">
        <div
          class="h-20 w-20 flex items-center rounded-full justify-center origin-left pointer-events-auto shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0"
        >
          <img alt="Avatar" class="rounded-full object-cover h-16 w-16" :src="AvatarImage" />
        </div>
        <!--  <Logo class="ml-4" /> -->
      </div>

      <TitleComponent :text="t('home.title')" />

      <p class="mt-6 text-base text-zinc-600 dark:text-zinc-400">
        {{ t('home.description1') }}
        <a href="https://filmin.es" target="_blank" class="filmin-word"
          ><span class="filmin-word__film">Film</span><span class="filmin-word__in">in</span></a
        >
        {{ t('home.description2') }}
      </p>

      <div class="mt-6 flex gap-4">
        <a
          class="group -m-1 p-1"
          aria-label="Follow on LinkedIn"
          href="https://www.linkedin.com/in/brunomiguelbarrospinto"
        >
          <img alt="linkedin" class="object-cover h-5 w-5" :src="LinkedinImage" />
        </a>

        <a
          class="group flex text-sm font-medium text-zinc-600 dark:text-zinc-400 transition hover:text-teal-500"
          href="mailto:brunomiguelbarrospinto@gmail.com"
        >
          <img alt="gmail" class="object-cover h-5 w-5" :src="GmailImage" />
        </a>
      </div>
    </div>

    <!--     <GaleryComponent /> -->
    <div
      class="mt-24 md:mt-28 mx-auto grid max-w-xl grid-cols-1 gap-y-16 lg:max-w-none lg:grid-cols-2"
    >
      <div>
        <h2 class="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
          <RouterLink class="relative block hover:text-teal-500" to="/posts">
            {{ t('nav.posts') }}
            <span></span>
          </RouterLink>
        </h2>
        <PostListItemComponent
          :key="'post-list-item-component' + k"
          v-for="(post, k) in posts"
          :post="post"
        />
      </div>
      <div class="space-y-10 lg:pl-12 xl:pl-20">
        <h2 class="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
          <RouterLink class="relative block hover:text-teal-500" to="/experiences">
            {{ t('nav.experiences') }}
            <span></span>
          </RouterLink>
        </h2>
        <ExperiencesListComponent isMinimal />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import TitleComponent from '@/components/common/TitleComponent.vue'

import AvatarImage from '@/assets/img/avatar.jpg'
import GaleryComponent from '@/components/home/GaleryComponent.vue'
import PostListItemComponent from '@/components/posts/PostListItemComponent.vue'
import ExperiencesListComponent from '@/components/experiences/ExperiencesListComponent.vue'
import LinkedinImage from '@/assets/img/linkedin.png'
import GmailImage from '@/assets/img/gmail.png'
import usePostsStore from '@/stores/posts'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const postsStore = storeToRefs(usePostsStore())
const posts = postsStore.getPosts
</script>

<style scoped lang="scss">
.filmin-word {
  @apply bg-black;
  @apply uppercase;
  @apply font-bold;
  @apply px-1 py-0.5;
  @apply tracking-tighter;

  &__film {
    color: #02ffa1;
  }
  &__in {
    color: #ffffff;
  }
}
</style>
